.AfterQuestionGeneralChoseGender, .AfterQuestionGeneralChosePoint {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #AAAAAA;
    width: 194px;
    margin: auto;
    padding-top: 24px;
}

.AfterQuestionGeneralChoseGenderChosen, .AfterQuestionGeneralChosePoint {
    color: #000000;
}

.AfterQuestionGeneralChoseGender div, .AfterQuestionGeneralImg img, .AfterQuestionGeneralChosePoint img {
    cursor: pointer;
}

.AfterQuestionGeneralChosePoint{
    justify-content: space-between;
    width: 80%;
}
.AfterQuestionGeneralPoint {
    height: 290px;
}