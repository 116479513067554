.MainSlider__Item__Tabs {
    width: 345px !important;
    height: 138px;
    top: 104px;
    left: 20px;
    gap: 0px;
    border-radius: 10px;
    background: #467090;
    color: #FFFFFF;
    margin-bottom: 20px;
    /*margin-right: 20px;*/
    /*margin-left: 20px;*/
    /*margin-top: 23px;*/
}

.slick-list {
    margin-right: 40px;
}

.slick-next:before {
    content: '';
}

.MainSlider__Item__text__Tabs {
    padding: 20px 0 0 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.38px;
    text-align: left;
}

.MainSlider__Item__img__Tabs {
    width: 12px;
    height: 16px;
    cursor: pointer;
    position: relative;
    left: 310px;
    top: -2px;
}

.MainSlider__Item__img__Tabs:hover {
    /*background-color: #467090;*/
}

.MainSlider__Item__question__Tabs {
    /*width: 54px;*/
    /*height: 16px;*/
    top: 208px;
    left: 40px;
    padding: 1px 0px 1px 0px;
    background-image: url("question.svg");
    background-repeat: no-repeat;
    padding-left: 21px;
}

.MainSlider__Item__time__Tabs {
    /*width: 50px;*/
    /*height: 16px;*/
    background-image: url("time.svg");
    background-repeat: no-repeat;
    padding-left: 21px;
    margin-left: 19px;
}

.question_time__Tabs {
    margin-left: 20px;
    margin-bottom: 16px;
    display: flex;
    margin-top: 40px;
}

.QuestionListTabsBox button {
    font-size: 18px;
    text-transform: lowercase;
    color: #AAAAAA;
    min-width: 0;
    display: inline-flex;
    padding-right: 15px;
    padding-top: 44px;
    padding-left: 6px;
    padding-bottom: 0;
}

.QuestionListTabsBox {
    margin-left: 26px;
}

.Mui-selected {
    color: #000000 !important;
}

.css-jpln7h-MuiTabs-scroller {
    position: static !important;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: #FFFFFF !important;
    display: none;
}

.css-1gsv261 {
    border: none !important;
}

.css-ttwr4n {
    background-color: white !important;
}

.css-19kzrtu {
    padding: 23px 20px 0 !important;
}

.scroll-tags {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #fff;
    z-index: 10;
    overflow-x: auto;
    margin-top: 20px;
}
.scroll-tags::-webkit-scrollbar {display: none}

.tag-container:before, .tag-container:after {content: ''; width: 20px; height: 20px; flex-shrink: 0}

.scroll-tags .tag-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.scroll-tags .tag-container .scroll-tag {
    white-space: nowrap;
    color: #AAAAAA;
    cursor: pointer;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 400;
    transition: 0.5s;
}

.scroll-tags .tag-container .scroll-tag.active {
    color: #000;
}

.scroll-tags .tag-container .scroll-tag+.scroll-tag {
    margin-left: 20px;
}

.scroll-tags::-webkit-scrollbar {
    height: 5px;
    background: #fff;
}

.scroll-tags::-webkit-scrollbar-track {
    background-color: #fff;
}

.scroll-tags::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 100px;
}

.scroll-questions.container {
    margin-top: 20px;
}

.question-item + .question-item {
    margin-top: 20px;
}

.question-item {
    border-radius: 10px;
    background: #467090;
    color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    padding: 20px;
    position: relative;
    min-height: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: column;
}


.question-item .question-title {overflow: hidden; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; height: 3.9em}


.question-item .question-options {
    padding-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 12px;
}

.question-item .question-options .question-votes::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    transform: translateY(3px);
    background: url(question.svg) no-repeat center/contain;
}

.question-item .question-options .question-data::before {
    content: "";
    display: inline-block;
    width: 15px;
    transform: translateY(3px);
    height: 15px;
    margin-right: 10px;
    background: url(time.svg) no-repeat center/contain;
}