.FirstQuestionContent {
    font-size: 18px;
}

.FirstQuestionContentTitle {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6D98BB;
    color: #FFFFFF;
    /*margin: auto;*/
    /*padding-top: 18px;*/
    /*padding-bottom: 18px;*/
}

.FirstQuestionContentLetsgo {
    margin: 80px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    width: 180px;
    height: 50px;
    border-radius: 360px;
    background: #6D98BB;
    cursor: pointer;
}

.FirstQuestionContentFooter {
    display: flex;
    justify-content: center;
    margin: 146px auto 0;
    height: 136px;
    width: 260px;
    background-image: url("../../images/Logo1.svg");
    background-repeat: no-repeat;
}

.FirstQuestionContentBlock1 {
    font-size: 22px;
    text-align: center;
    margin: 40px auto 20px;
}

.FirstQuestionContentCheckBox {
    /*margin: 64px auto auto 96px;*/
    margin: 40px auto auto 20px;

}

.FirstQuestionContentCheckBox div {
    margin-bottom: 40px;
}

.FirstQuestionContentCheckBox div:last-child {
    margin-bottom: 130px;
}

.FirstQuestionContentCheckBox label {
    font-size: 18px;
}

[type=radio],
[type=checkbox] {
    width: 22px;
    height: 22px;
    color: #467090;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #FFFFFF;
    transition: background 300ms;
    cursor: pointer;
    margin-right: 14px;
}

/* Pseudo element for check styling */
[type=radio]::before,
[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
}

/* Checked */
[type=radio]:checked:checked,
[type=checkbox]:checked {
    background-color: currentcolor;
}

[type=radio]:checked::before,
[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/* Disabled */
[type=radio]:disabled,
[type=checkbox]:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
}