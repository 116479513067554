.firstQuestionResultContent {
    /*font-family: "'../font/Inter", sans-serif;*/
    /*font-size: 18px;*/
}

.firstQuestionResultContentTitle {
    min-height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6D98BB;
    color: #FFFFFF;
    text-align: center;
    font-size: 19px;

}

.firstQuestionResultContentTitleContent {
    width: 282px;
    padding-top: 18px;
    padding-bottom: 18px;
}

.firstQuestionResultContentLetsgo {
    margin: 80px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    width: 180px;
    height: 50px;
    border-radius: 360px;
    background: #6D98BB;
    cursor: pointer;
    position: relative;
    z-index: 10;
}

.firstQuestionResultContentFooter {
    display: flex;
    justify-content: center;
    margin: 146px auto;
    height: 136px;
    width: 260px;
    background-image: url("../../images/Logo1.svg");
    background-repeat: no-repeat;
}

.firstQuestionResultContentBlock1 {
    font-size: 22px;
    text-align: center;
    margin: 47px auto 24px;
}

.firstQuestionResultContentFooter2 {
    display: none;
}

.firstQuestionResultContentFooter3 {
    background-image: url("../../images/Logo1.svg");
}
.firstQuestionResultContentFooter5 {
    position: absolute;
}
