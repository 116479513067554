.Notification {
    margin: 32px auto 16px 20px;
    display: flex;
    align-items: center;
    height: 26px;
}

.Notification_pre {
    margin-left: 9px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20.68px;
    text-align: left;
    color: #000000;
    max-width: 300px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, с 1999 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ поддерживается в IE, Safari, и Firefox 3.1.*/
}

@media screen and (max-width: 320px) {
    .Notification {
        margin: 32px 0 30px 20px;
    }
}

@media (min-width: 321px) and (max-width: 375px) {
    .Notification {
        margin: 32px 0 30px 20px;
    }
}