.RegistrationContentTitle {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #467090;
    color: #FFFFFF;
    margin-bottom: 40px
}

.RegistrationContentSelect {margin-bottom: 40px; margin-top: 10px}

.RegistrationContentSelect select {
    text-align: left;
    width: 100%;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 18px;
    height: 40px;
    appearance: none;
    background-color: #fff;
    background: url('../../../src/images/select_arrow.svg'), #EDEEF3;
    background-repeat: no-repeat, repeat;
    background-position: right 15px top 50%, 0 0;
    /*background-size: .65em auto, 100%;*/
    border: none;
    outline: none;
}

.RegistrationContentAge {
    margin-top: 40px;
}

.RegistrationContentAgePrice {
    margin-left: auto;
    margin-top: 15px;
}

input[type=range] {
    -webkit-appearance: none; width: 100%
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    background: #EDEEF3;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #467090;
    margin-top: -15px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #EDEEF3;
}

.RegistrationContentLetsgo {
    margin: 80px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    width: 180px;
    height: 50px;
    border-radius: 360px;
    background: #467090;
}

.RegistrationContentFooter {
    display: flex;
    justify-content: center;
    margin: 146px auto;
    height: 136px;
    width: 260px;
    background-image: url("../../images/Logo1.svg");
    background-repeat: no-repeat;
}

button {
    border: none;
}