.footer {
    width: auto;
    margin-top: 95px;
}

.marginTop {
    width: auto;
    margin-top: 0;
}

.inputButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -60px;
    position: relative;
    z-index: 9;
}

.inputButton img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.inputButton input {
    width: 180px;
    height: 50px;
    gap: 0px;
    border-radius: 360px;
    background: #467090;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    margin-left: 40px;
    border: #FFFFFF;
    z-index: 5;
}

.footerImgBackground {
    width: 260px;
    height: 136px;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
    display: flex;
    margin: auto;
    /*opacity: 30%;*/
    z-index: -1;
    bottom: 100%;
}

.enjoy {
    margin: 40px auto 0;
    padding: 20px 0;
    position: relative;
    background: #467090;
    /*top: -270px;*/
    z-index: 5;
}

.hashtagsBlock {
    display: flex;
    justify-content: center;
    color: #000000;
}

.hashtagsBlock a,
.hashtagsBlock div {
    border-radius: 210px;
    background: #FFFFFF;
    display: flex;
    height: 34px;
    align-items: center;
    padding: 0px 20px;
    text-decoration: none;
    color: #000000;
}
.hashtagsBlock a span {color: #ccc; margin-right: 2px}

.hashtagsBlock a:not(:first-child),
.hashtagsBlock div:not(:first-child) {
    margin-left: 12px;
}

.enjoyBlock1 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    z-index: 5;
}

.enjoyBlock2 {
    font-size: 16px;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
}

.enjoyBlock1,
.enjoyBlock2 {
    color: #FFFFFF;
}