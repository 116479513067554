.header {
    left: 0;
    top: 0;
    padding: 20px 0;
    align-items: center;
    width: 100%;
    height: 60px;
    z-index: 20;
}

.hide {
    display: none;
}


.header__nav {
    margin-right: auto;
}

.header__nav-list {
    display: flex;
    list-style-type: none;
}

.header__nav-item {
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
}

.header__nav-item:nth-child(n + 2) {
    margin-left: 30px;
}

/*@media (max-width: 576px) {*/
.header__nav {
    position: absolute;
    top: 0;
    /*left: -391px;*/
    display: flex;
    flex-direction: column;
    /*width: 389px;*/
    height: auto;
    background-color: #fff;
    transition: transform 0.3s linear;
    /*left: -390px;*/
    display: none;
    left: -98%;
    width: 98%;
}

.header__nav ul {
    padding-left: 0;
    align-items: flex-start;
}

.header__nav.active {
    transform: translateX(100%);
    /*width: 385px;*/
    /*left: -389px;*/
    display: block;
}

.header__nav-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}

.header__nav-item {
    margin: 10px 0;
    font-weight: 500;
    color: #000000;
}

.header__nav-list li:first-child {
    margin-top: 0;
}

.header__nav-item:nth-child(n + 2) {
    /*margin-left: 0;*/
}

/*}*/

.headerMenuSearch {
    width: 300px;
    height: 40px;
    top: 40px;
    left: 70px;
    gap: 0px;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 12px 146px 11px 16px;
    background-image: url("searchBackgroundImage.svg");
    margin-top: 40px;
    margin-left: 10px;
    background-repeat: no-repeat;
    background-position: center right 15px;
    cursor: pointer;
    margin-right: 15px;
    background-color: #EDEEF3;
}

.headerMenuSearch::placeholder {
    color: #AAAAAA;
}

.header__nav-listHr {
    margin-top: 27px;
    margin-bottom: 13px;
    width: 98%;
}

.header__nav-listHr2 {
    margin-top: 14px;
    margin-bottom: 23px;
    width: 98%;
}

.headerUserInfo {
    margin-left: 30px;
    margin-bottom: 18px;
}

.headerUserInfoDetails {
    color: #AAAAAA;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
}

.headerUserInfoCopy p {
    margin-top: 0;
}

.headerUserInfoKey img {
    margin-left: 10px;
    cursor: pointer;
}

.headerFooterLogo {
    background-image: url("../../images/Logo1.svg");
    width: 260px;
    height: 136px;
    position: absolute;
    display: flex;
    margin: auto;
    left: 60px;
    z-index: 1;
    bottom: 215px;
}

.headerDocument1 {
    margin-left: 30px;
    margin-bottom: 15px;
    position: relative;
    z-index: 9;
}

.headerDocument2 {
    margin-left: 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
}

.headerFooter {
    /*margin: auto;*/
    height: 199px;
    background: #467090;
    width: 98%;
    margin-left: 0;
    position: relative;
    z-index: 9;
}

.headerFooter span {
    font-size: 20px;
    color: #FFFFFF;
    margin: 41px auto 0;
    display: flex;
    justify-content: center;
    text-align: center;
}

.headerFooter div {
    font-size: 16px;
    color: #000000;
    height: 50px;
    width: 180px;
    margin: 30px auto 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    background: #FFFFFF;
}


@media (width: 320px) {
    .headerFooter {
        /*bottom: 160px;*/
    }

    .headerFooterLogo {
        bottom: 160px
    }

    .header__nav {
        left: -310px;
        width: 310px;
    }
}

@media (min-width: 321px) and (max-width: 375px) {
    .headerFooter {
        /*bottom: 160px;*/
    }

    .headerFooterLogo {
        bottom: 215px
    }

    .header__nav {
        /*left: -372px;*/
        /*width: 373px;*/
    }
}