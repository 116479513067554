.SubscribedUserQuestionTitle {
    padding-left: 20px;
    margin-top: 40px;
    font-size: 15px;
}

.main {
    margin-top: 40px;
}

.SubscribedUserQuestionBlock1 {
    font-size: 26px
}


.SubscribedUserQuestionBlock1Hr {
    border: 1px solid #EDEEF3;
    margin: 35px auto;
}

.SubscribedUserQuestionBlock2 {
    height: 40px;
    font-size: 18px;
    padding-left: 23px;
    border-radius: 5px;
    align-items: center;
    display: flex;
    margin-left: 20px;
    margin-right: 15px;
    background: url("../../images/trash.svg") #EDEEF3;
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    margin-bottom: 25px;
}

.SubscribedUserQuestionBlock3 {
    margin: 40px 0
}

.SubscribedUserQuestionBlock4 {
    background: #467090;
    margin-top: 40px;
    padding: 40px 0;
    position: relative;
}

.SubscribedUserQuestionBlock4Title {
    color: #FFFFFF;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
}

.SubscribedUserQuestionBlock4Submit {
    cursor: pointer;
    height: 50px;
    width: 180px;
    margin: auto;
    font-size: 16px;
    background: #FFFFFF;
    border-radius: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SubscribedUserQuestionFooter {
    background-image: url("../../images/Logo1.svg");
    width: 260px;
    height: 136px;
    position: absolute;
    margin: auto;
    /* opacity: 30%; */
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    bottom: 100%;
}