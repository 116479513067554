.slider-container {overflow: hidden}
.MainSlider {
    margin: 0 -10px 0 -10px; padding: 0 20px
}
.swiper-wrapper {padding: 10px}
.MainSlider__Item {
    min-height: 120px;
    gap: 0px;
    border-radius: 10px;
    background: #467090;
    color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.slick-list {
    /*margin-right: 40px;*/
    /*margin-right: 0 !important;*/
    /*overflow: auto;*/
    /*overflow-block: auto!important;*/
}

.slick-next:before {
    content: '';
}

.slick-prev,
.slick-next {
    display: none !important;
}

.MainSlider__Item__text {
    padding: 20px 0 0 20px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.add-to-favorites {
    width: 12px;
    height: 16px;
    background-image: url("favoriteIcon.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 20px;
    cursor: pointer;
}

.MainSlider__Item__img:hover {
    background-color: #467090;
}

.MainSlider__Item__question {
    /*width: 54px;*/
    /*height: 16px;*/
    top: 208px;
    left: 40px;
    padding: 1px 0px 1px 0px;
    background-image: url("question.svg");
    background-repeat: no-repeat;
    padding-left: 21px;
    font-size: 12px;
}

.MainSlider__Item__time {
    /*width: 50px;*/
    /*height: 16px;*/
    background-image: url("time.svg");
    background-repeat: no-repeat;
    padding-left: 21px;
    margin-left: 19px;
    font-size: 12px;
}

.question_time {
    margin-left: 20px;
    margin-bottom: 16px;
    display: flex;
    margin-top: 40px;
}

#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}


/*.swiper {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/


.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*.swiper-slide:nth-child(2n) {*/
/*    width: 60%;*/
/*}*/

/*.swiper-slide:nth-child(3n) {*/
/*    width: 40%;*/
/*}*/

.swiper-pagination {
    display: none;
}

.swiper-wrapper {
    z-index: unset;
}

.swiper {
    z-index: unset;
}