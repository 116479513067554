[class*=flex-] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.flex-betwen {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.flex-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.flex-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.flex-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.flex-vertical-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-srethc {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex-collumn {
    flex-direction: column;
}

.flex-collumn>* {
    width: 100%;
    display: block;
}

.grow {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.header {
    padding-top: 20px;
}

.header .btn-menu {
    background: #467090;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 3102;
}

img {
    display: block
}

hr {
    background: #edeef3;
    height: 1px;
    border: none
}

input,
select,
textarea,
button {
    -webkit-appearance: none;
    outline: none;
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit
}

input[type=text] {
    display: block;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 0 20px;
    background: #EDEEF3;
    height: 40px
}

input[type=text]:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
}

.header .btn-menu span {
    display: block;
    position: absolute;
    left: 10px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
    width: 20px;
    transition: bottom 250ms ease 250ms, top 250ms ease 250ms, transform 250ms ease 0ms
}

.header .btn-menu span:nth-of-type(1) {
    top: 12px
}

.header .btn-menu span:nth-of-type(2) {
    top: calc(50% - 1px)
}

.header .btn-menu span:nth-of-type(3) {
    bottom: 12px
}

.header.active .btn-menu span {
    transition: bottom 250ms ease 0ms, top 250ms ease 0ms, transform 250ms ease 250ms
}

.header.active .btn-menu span:nth-of-type(1) {
    top: calc(50% - 1px);
    transform: rotateZ(45deg)
}

.header.active .btn-menu span:nth-of-type(2) {
    opacity: 0
}

.header.active .btn-menu span:nth-of-type(3) {
    bottom: calc(50% - 1px);
    transform: rotateZ(-45deg)
}



.grey-text {
    color: #AAAAAA;

}

.App {
    position: relative;
}

.header .search-block {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 10px;

    position: relative;
    box-sizing: border-box;
}

.header .search-block .value-block {
    position: absolute;
    left: 10px;
    top: 45px;
    right: 0px;
    height: 60vh;
    background: #EDEEF3;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 5px;
    transition: 250ms;
    max-height: 0;
    overflow: hidden;
}

.search-block .value-text::-webkit-scrollbar {
    display: none
}

.header .search-block input:focus+.value-block {
    max-height: 60vh;
}

.content a {
    font-weight: 500;
    text-decoration: underline
}

.content h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 26px
}

.content p+p,
.content p+ul,
.content p+ol,
.content ol+p,
.content ul+p,
.content ul+ul,
.content li+li {
    margin-top: 10px
}

.content ul li {
    position: relative;
    padding-left: 15px
}

.content ul li:before {
    width: 5px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 10px;
    content: '';
    background: #000
}

.content ol li {
    list-style-type: none
}

.content ol {
    counter-reset: list1
}

.content ol li:before {
    counter-increment: list1;
    content: counter(list1) ". ";
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px
}

.pageQuestion {
    margin-top: 44px;
}

.mainQuestion {
    font-size: 22px;
    font-weight: 400;
    text-align: left;
}

.votesTimeFavorite {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    font-size: 10px;
}

.search-block .value-text {
    padding: 10px;
    overflow-y: auto;
    height: 100%;
}

.votesTimeFavorite div {
    display: flex;
    align-items: center;
}

.votesTimeFavorite div:not(:last-child) {
    margin-right: 40px;
}

.votesTimeFavorite img {
    margin-right: 5px;
}

.checkBox {
    margin-top: 40px
}

.checkBox div {
    margin-bottom: 20px;
}

.checkBox div:last-child {
    margin-bottom: 120px;
}

.checkBox label {
    font-size: 16px;
}

[type=checkbox] {
    width: 26px;
    height: 26px;
    color: #467090;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #FFFFFF;
    transition: background 250ms;
    cursor: pointer;
    margin-right: 14px;
}

/* Pseudo element for check styling */

[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
}

/* Checked */

[type=checkbox]:checked {
    background-color: currentcolor;
}

[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/* Disabled */

[type=checkbox]:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
}

.AfterQuestionGeneral {
    padding-bottom: 20px;
}

.AfterQuestionGeneralImg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 29px;
}

.pageQuestion .mini-tabs {
    height: 30px;
    margin-bottom: 20px;
    gap: 20px;
}

.pageQuestion .mini-tabs .mini-tab-item {
    color: #AAAAAA;
}

.pageQuestion .mini-tabs .mini-tab-item.active {
    color: #000;
}

.map {
    overflow-x: auto;
}

.map .map-container {
    width: 250%;
}

.AfterQuestionGeneralImg img:not(:first-child) {
    margin-left: 45px;
}

.AfterQuestionGeneralPercent pre {
    margin: 0;
}

.AfterQuestionGeneralPercentYes,
.AfterQuestionGeneralPercentNo,
.AfterQuestionGeneralPercentOfficiallyNo {
    display: flex;
    margin-left: 20px;
    margin-right: 15px;
}

.AfterQuestionGeneralPercentYes,
.AfterQuestionGeneralPercentNo {
    margin-bottom: 10px;
    border-radius: 5px 0 0 5px;
    background: #EDEEF3;
}

.AfterQuestionGeneralPercentOfficiallyNo {
    margin-bottom: 35px;
    background: #EDEEF3;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
}

.AfterQuestionGeneralPercentYesDiv,
.AfterQuestionGeneralPercentNoDiv {
    display: flex;
    align-items: center;
    padding: 6px 0 6px 10px;
    font-size: 16px;
    color: #FFFFFF;
}

.AfterQuestionGeneralPercent {
    margin-bottom: 35px;
}

.AfterQuestionGeneralPercentYesDiv {
    background: #467090;
    width: 61%;
    border-radius: 5px 0 0 5px;
}

.AfterQuestionGeneralPercentNoDiv {
    background: #467090;
    width: 45%;
    border-radius: 5px 0 0 5px;
}

.AfterQuestionGeneralPercentYesDiv pre,
.AfterQuestionGeneralPercentNoDiv pre {
    font-weight: 700;
}

.AfterQuestionGeneralPercentOfficiallyNoPre1 {
    padding: 6px 0 6px 10px;
    background: #467090;
    border-radius: 5px 0 0 5px;
    color: #FFFFFF;
}

.AfterQuestionGeneralPercentOfficiallyNo span,
.AfterQuestionGeneralPercentOfficiallyNo pre {
    color: #000000;
    font-size: 16px;
}

.AfterQuestionGeneralPercentOfficiallyNo pre {
    font-weight: 700;
}

.AfterQuestionGeneralPercentChoseAge {
    align-items: center;
    color: #aaa;
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin: auto;
    padding-top: 24px;
    width: 80%;
}

.AfterQuestionGeneralChoseGenderSpaceBetween {
    margin: auto 20px;
}

.wave {
    background: rgba(255, 255, 255, .4);
    border-radius: 50%;
    transform: scale(0);
    animation: wave .5s linear;
    pointer-events: none;
    position: absolute;
}

@keyframes wave {
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.container {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}

.loaded {
    position: relative;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loaded::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(250, 250, 250, 0.6);
    backdrop-filter: blur(2px);
    z-index: 2;
}

.loaded::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    width: 40px;
    height: 40px;

    border-radius: 1000px;
    border-left: 3px solid transparent;
    border-right: 3px solid #467090;
    border-top: 3px solid #467090;
    border-bottom: 3px solid #467090;
    -webkit-animation: rotate-center 1s linear infinite both;
    animation: rotate-center 1s linear infinite both;
    z-index: 3;
}

a {
    display: block;
    text-decoration: none;
}

.menu-content {
    position: absolute;
    left: 0px;
    width: 100%;
    max-width: 400px;
    bottom: 0px;
    top: 0px;
    z-index: 100;
    background: #fff;
    padding-top: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
}

.menu-content .borders-content ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.menu-content .borders-content ul li a {
    color: #000000;
}

.menu-content .borders-content ul li+li {
    margin-top: 10px;
}

.borders-content+.borders-content {
    border-top: 1px solid #edeef3;
}

.menu-content .borders-content {
    padding-top: 20px;
    padding-bottom: 20px;
}

.menu-content .borders-content a.copy-link::after {
    content: "";
    display: inline-block;
    margin-left: 20px;
    width: 20px;
    height: 20px;
    background: url(../images/copy-icon.svg) no-repeat center/contain;
}

.menu-content .blue-bottom {
    background: #467090;
    color: #fff;
    margin-top: auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}


.menu-content .blue-bottom .text-block {
    font-size: 16px;
    margin-bottom: 20px
}

.menu-content .btn-subscribe .btn-white {
    display: inline-block;
    color: #467090;
    background: #fff;
    padding: 0;
    width: 180px;
    height: 50px;
    line-height: 50px;
    text-decoration: none;
    border-radius: 25px;
}

.menu-content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    visibility: hidden;
    transition: 250ms;
}

.active .menu-content {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;

}


.AfterQuestionGeneralPercentDiagram {
    margin-bottom: 20px;
    border-radius: 5px;
    background: #EDEEF3;
    height: 30px;
    position: relative;
}

.AfterQuestionGeneralPercentDiagram .progress-bar {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: #467090;
    transition: 1s;
    transition-delay: .5s;
    width: 0%;
    z-index: 1;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
}

.AfterQuestionGeneralPercentDiagram .value-data {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 0;
    white-space: nowrap;
    color: #000;
    overflow: hidden;
    left: 3px;
    height: 30px;
    line-height: 30px
}

.AfterQuestionGeneralPercentDiagram .progress-bar .value-data {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 2;
    white-space: nowrap;
    color: #fff;
    overflow: hidden;
    padding-left: 20px;
    height: 30px;
    line-height: 30px
}

.input-delete {
    background: #EDEEF3;
    border-radius: 5px;
    width: 100%;
}

.input-delete+.input-delete {
    margin-top: 20px;
}

.input-delete .delete-input {
    width: 35px;
    background: url(../images/trash.svg) no-repeat center;
    background-size: 40%;
}

.map {
    width: 100%;
    overflow: auto;
}

.map::-webkit-scrollbar {
    height: 5px;
    background: #fff;
}

.map::-webkit-scrollbar-track {
    background-color: #fff;
}

.map::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 100px;
}

.SubscribedUserQuestionBlock2Hr {
    border: 1px solid #EDEEF3;
    margin: 10px auto;
}

.pt-20 {
    padding-top: 20px;
}

.create-ansfer {
    cursor: pointer;
    color: #467090;
}

.error {
    color: red;
}

.search-block .value-text::-webkit-scrollbar {
    width: 5px;
    background: #fff;
}

.search-block .value-text {
    padding-bottom: 20px;
}

.search-block .value-text::-webkit-scrollbar-track {
    background-color: #fff;
}

.search-block .value-text::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 100px;
}

.search-block .value-text .value-item span::before {
    content: '#';

}

.search-block .value-text .value-item span {
    margin-left: 2px;
    background: #467090;
    color: #fff;
    display: inline-block;
    border-radius: 3px;
    padding: 5px;
}

.search-block .value-text .value-item+.value-item {
    margin-top: 3px;
}

.search-block .value-text .value-item.hide-item {
    display: none;
}

.App {
    width: 100%;
}

.pageQuestion .mainQuestion {
    font-weight: bold;
    text-align: center;
}

.main .tabs {
    padding-bottom: 40px;
    position: relative;
    z-index: 3;
}

.enjoy {

    z-index: 1;
}

.scroll-questions.container {

    z-index: 3;
}

.ansfer-progress-wrap {
    position: relative;
    z-index: 3;
    min-height: 220px;
}

.favspan img {
    display: inline-block;
    transform: translateY(4px);
}

.ansfer-progress-wrap .progress-bar {
    position: relative;
    background: #EDEEF3;
    height: 30px;
    border-radius: 4px;

    overflow: hidden;
}

.ansfer-progress-wrap .progress-bar .default {

    height: 100%;
    white-space: nowrap;
}

.ansfer-progress-wrap .progress-bar .value .padding,
.ansfer-progress-wrap .progress-bar .default .padding {
    padding: 5px 10px;
    display: block;
}

.ansfer-progress-wrap .progress-bar .value {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    background: #467090;

    white-space: nowrap;
    overflow: hidden;
    transition: 0.5s;
}

.ansfer-progress-wrap .progress-bar {}

.ansfer-progress-wrap .progress-bar+.progress-bar {
    margin-top: 10px;
}