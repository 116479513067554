.MainSlider2 {
    margin-top: 0px;
    margin-left: 10px;
}

.MainSlider__Item2 {
    min-height: 150px;
    gap: 0px;
    border-radius: 10px;
    background: #181716;
    color: #FFFFFF;
    background-image: url("back.svg");
    background-repeat: no-repeat;
    background-position: center right 33px;
    position: relative;
}

.slick-list {
    margin-right: 40px;
}

.slick-next:before {
    content: '';
}

.slick-prev,
.slick-next {
    display: none !important;
}

.MainSlider__Item__text2 {
    padding: 17px 0 0 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.38px;
    text-align: left;
}

.MainSlider__Item__img2 {
    margin-top: 28px;
    margin-left: 20px;
    width: 64px;
    height: 16px;
    top: 344px;
    left: 40px;

}

.MainSlider__Item__img2:hover {
    background-color: #6D98BB;
}

.MainSlider__Item__time2 {
    font-size: 10px;
    font-weight: 400;
    text-align: left;

    position: absolute;
    bottom: 20px;
    left: 20px;
}

.swiper-slide .MainSlider__Item__time2 img {
    display: inline-block;
    width: 15px;
    height: 15px;
    object-fit: contain;
    transform: translateY(5px);
}

.MainSlider__Item__time2 pre {
    margin-left: 10px;
    display: inline-block;

}

#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}

/*.swiper {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/


.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*.swiper-slide:nth-child(2n) {*/
/*    width: 60%;*/
/*}*/

/*.swiper-slide:nth-child(3n) {*/
/*    width: 40%;*/
/*}*/

.swiper-pagination {
    display: none;
}

.swiper-wrapper .swiper {
    z-index: unset;
}

.backgroudRed {
    background: #E60000;
}

/*.SaySVG{*/
/*    width: 31.82px !important;*/
/*    height: 31.82px !important;*/
/*}*/

.MainSlider__Item__img3 img {
    margin-top: 15px;
    margin-left: 18px;
    width: 29px;
    height: 36px;
    top: 344px;
    left: 40px;
}

.MainSlider__Item__text3 {
    padding: 10px 0 0 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.38px;
    text-align: left;
}